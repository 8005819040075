import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useParams, Link } from 'react-router-dom'
import {
    Typography,
    Grid,
    makeStyles,
    Box,
    FormControl,
    Button,
    MenuItem,
    CircularProgress,
    IconButton
} from '@material-ui/core'
import { Delete } from '@material-ui/icons';
import moment from 'moment'
import { CellChange, NumberCell, ReactGrid } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import "./assets/styles.css";

import { SummaryValueColumns, SummaryDateColumns } from "./Constants";
import { getColumns, FIXED_COLUMNS } from "./Columns";
import {
    getRows,
    HEADER_ROW_ID,
    BLANK_ROW_ID,
    SUBHEADER_ROW_ID,
    TOTAL_ROW_ID,
    DATE_FORM_FIELD_ROW_ID,
    DATE_FORM_941X_ROW_ID,
    FORM_USED_ROW_ID,
    TOTAL_REFUND_RECEIVED_ROW_ID,
    REFUND_TYPE_ROW_ID,
    DATA_RECEIVED_ROW_ID,
    ATTACH_ROW_ID,
    NOTE_ID
} from "./Rows";
import { calculateOutputVariables } from "./OutputVariables";

import { PButton, PIconText, PSelect } from '@src/components/PCustom'
import { AlertUtil } from '@src/utils'
import TooltipCellTemplate from './TooltipCellTemplate'

import { IconDownArrow } from '@src/components/PCustom/SVGIcons'

import ENUMS from '@src/config/Enums'
import Constants from '@src/config/Constants'
import TrackerAPI from '../TrackerAPI'
const colorS = Constants.COLORS
const trackerAPI = new TrackerAPI()

const FIXED_ROWS = [
    HEADER_ROW_ID,
    SUBHEADER_ROW_ID,
    BLANK_ROW_ID,
    TOTAL_ROW_ID,
    DATE_FORM_FIELD_ROW_ID,
    FORM_USED_ROW_ID,
    TOTAL_REFUND_RECEIVED_ROW_ID,
    REFUND_TYPE_ROW_ID,
    DATA_RECEIVED_ROW_ID,
    ATTACH_ROW_ID,
    DATE_FORM_941X_ROW_ID,
    NOTE_ID
]

const FORM_TYPES = ['8974', '8974_2023']

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'block',
        width: '100%',
        position: 'relative',
        padding: '0',
        margin: 'auto',
        overflow: 'auto',
        '& > .reactgrid': {
            margin: 'auto',
        }
    },
    selector: {
        height: '40px',
        marginRight: '12px',
        '& > div': {
            padding: '5px'
        }
    },
    deleteButton: {
        color: `rgb(220, 0, 78)`,
        border: `1px solid`,
        '&:hover': {
            color: `rgb(220, 0, 78)`,
            border: `1px solid`,
        }
    },
    buttonProgress: {
        // style for button loading
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    wrapperButton: {
        position: 'relative'
    },
    attachments: {
        borderCollapse: 'collapse',
        "& tr": {
            "& td": {
                padding: 5,
            },
            "& th": {
                fontWeight: 500,
                padding: 5
            },

            "& td.currentCol": {
                border: `1px solid #000`,
            }
        }
    },
    uploadInput: {
        display: 'none',
    },
}))

export default ({ storeNotification, onLoading }) => {
    const classes = useStyles()
    const history = useHistory()

    const [accessToken, setAccessToken] = useState(() => '');

    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState('');
    const [isDeleteRow, setIsDeleteRow] = useState(false);
    const [isDeleteCol, setIsDeleteCol] = useState(false);
    const [currentColId, setCurrentColId] = useState('');
    const [currentRowId, setCurrentRowId] = useState('');
    const [processing, setProcessing] = useState(false);
    const [loading, setLoading] = useState(false);

    const [formType, setFormType] = useState('8974');

    const [trackLines, setTrackLines] = useState(() => []);
    const [quarters, setQuarters] = useState(() => []);
    const [utilColumns, setUtilColumns] = useState(() => []);
    const [formFiled, setFormFiled] = useState(() => []);
    const [formUsed, setFormUsed] = useState(() => []);
    const [refundTypes, setRefundTypes] = useState(() => []);
    const [dateReceived, setDateReceived] = useState(() => []);
    const [totalRefundReceived, setTotalRefundReceived] = useState(() => []);
    const [attach, setAttach] = useState(() => []);
    const [form941x, setForm941x] = useState(() => []);
    const [notes, setNotes] = useState(() => []);

    const columns = getColumns(utilColumns)
    const inputVariables = { trackLines, quarters, formFiled, formUsed, refundTypes, dateReceived, attach, notes, totalRefundReceived, form941x };

    const outputVariables = calculateOutputVariables(inputVariables);
    const companyTrackData = { ...inputVariables, ...outputVariables };

    const rows = getRows(companyTrackData);

    const applyChange = (change) => (rows) => {
        return rows.map((row) => {
            if (row._id === change.rowId) {
                if (SummaryValueColumns.includes(change.columnId)) {
                    row[change.columnId] = change.newCell.value
                } else if (SummaryDateColumns.includes(change.columnId)) {
                    row[change.columnId] = change.newCell.date
                } else if (change.columnId == 'claim_type') {
                    if (change.previousCell.isOpen !== change.newCell.isOpen) {
                        row['claim_type_is_open'] = change.newCell.isOpen
                    }
                    if (change.previousCell.selectedValue !== change.newCell.selectedValue) {
                        row[change.columnId] = change.newCell.selectedValue
                    }
                } else {
                    const tax_values = [...row['tax_values']]
                    tax_values[change.columnId] = change.newCell.value
                    const total_tax = tax_values.reduce((t, c) => t + c, 0)
                    if (total_tax > row['credits_from_study'] - row['income_tax_allocation']) {
                        AlertUtil.showErrorAlert(storeNotification, "Value is not available.")
                    } else {
                        row['tax_values'][change.columnId] = change.newCell.value
                    }

                }
            }
            return row
        })
    }

    useEffect(() => {
        loadData()
        const storedCompany = localStorage.getItem('company')
        if (storedCompany) {
            handleChangeCompany(storedCompany)
        }

        if (window.localStorage.getItem('currentPtUser') !== null) {
            const u = JSON.parse(window.localStorage.getItem('currentPtUser'))
            const token = u.access_token
            if (token) {
                setAccessToken(token)
            }
        }
    }, [])

    const loadData = async () => {
        try {
            const companyResp = await trackerAPI.getCompanies()
            setCompanies(companyResp.data)
        } catch (e) {
            const m = e.message ? e.message : e.toString()
            AlertUtil.showErrorAlert(storeNotification, m)
        }
    }
    const handleChangeCompany = async (e) => {
        if (!e) return
        onLoading(true)
        setSelectedCompany(e)
        localStorage.setItem('company', e)
        try {
            const trackResp = await trackerAPI.getTrackData({ company: e })
            setTrackLines(trackResp.data.track_lines || [])
            setQuarters(trackResp.data.quarters || [])
            setUtilColumns(trackResp.data.quarters.map((elem, index) => {
                return {
                    columnId: index,
                    width: 120
                }
            }))
            setFormFiled(trackResp.data.form_filed || [])
            setFormUsed(trackResp.data.form_used || [])
            setRefundTypes(trackResp.data.refund_types || [])
            setDateReceived(trackResp.data.date_received || [])
            setTotalRefundReceived(trackResp.data.total_refund_received || [])
            setAttach(trackResp.data.attach || [])
            setNotes(trackResp.data.notes || [])
            setForm941x(trackResp.data.form_941x || [])

        } catch (e) {
            const m = e.message ? e.message : e.toString()
            AlertUtil.showErrorAlert(storeNotification, m)
        }
        onLoading(false)
    }


    const handleChanges = (changes) => {
        changes.forEach((change) => {
            setTrackLines((trackLine) => applyChange(change)(trackLine));
            if (change.rowId == SUBHEADER_ROW_ID) {
                quarters[change.columnId] = change.newCell.text
                setQuarters(quarters)
            }
            if (change.rowId == DATE_FORM_941X_ROW_ID) {
                form941x[change.columnId] = change.newCell.value
                setForm941x(form941x)
            }

            if (change.rowId == DATE_FORM_FIELD_ROW_ID) {
                formFiled[change.columnId] = change.newCell.date
                setFormFiled(formFiled)
            }

            if (change.rowId == TOTAL_REFUND_RECEIVED_ROW_ID) {
                totalRefundReceived[change.columnId] = change.newCell.value
                setTotalRefundReceived(totalRefundReceived)
            }

            if (change.rowId == DATA_RECEIVED_ROW_ID) {
                dateReceived[change.columnId] = change.newCell.date
                setDateReceived(dateReceived)
            }

            if (change.rowId == FORM_USED_ROW_ID) {
                formUsed[change.columnId] = change.newCell.text
                setFormUsed(formUsed)
            }
            if (change.rowId == REFUND_TYPE_ROW_ID) {
                refundTypes[change.columnId] = change.newCell.text
                setRefundTypes(refundTypes)
            }
            if (change.rowId == ATTACH_ROW_ID) {
                attach[change.columnId] = change.newCell.text
                setAttach(attach)
            }
            if (change.rowId.toString().indexOf(NOTE_ID) == 0) {
                const index = parseInt(change.rowId.toString().replace(NOTE_ID, ''))
                notes[index]['values'][change.columnId] = change.newCell.text
                setNotes(notes)
            }
        });
    }

    const handleAddRow = async () => {
        if (!selectedCompany) return
        try {
            const summaryResp = await trackerAPI.addNewSummary({ company: selectedCompany, quarters })
            setTrackLines([...trackLines, summaryResp.data])
        } catch (e) {
            const m = e.message ? e.message : e.toString()
            AlertUtil.showErrorAlert(storeNotification, m)
        }
    }

    const handleAddQuarterColumn = () => {
        if (!selectedCompany) return
        if (quarters.length == 0) {
            quarters.push(moment("2019-01-01").format("YYYY [Q]Q"))
        } else {
            const lastQuarter = quarters[quarters.length - 1]
            quarters.push(moment(lastQuarter, "YYYY [Q]Q").add(4, 'months').format("YYYY [Q]Q"))
        }
        setQuarters(quarters)
        setUtilColumns(quarters.map((elem, index) => {
            return {
                columnId: index,
                width: 100
            }
        }))
        const trackData = trackLines.map(elem => {
            elem.tax_values.push('')
            return { ...elem, tax_values: elem.tax_values }
        })
        setTrackLines(trackData)

        formFiled.push('')
        setFormFiled(formFiled)

        formUsed.push('')
        setFormUsed(formUsed)

        refundTypes.push('')
        setRefundTypes(refundTypes)

        dateReceived.push('')
        setDateReceived(dateReceived)

        totalRefundReceived.push('')
        setTotalRefundReceived(totalRefundReceived)

        form941x.push('')
        setForm941x(form941x)

        attach.push([])
        setAttach(attach)
        setNotes(notes.map(elem => {
            elem.values.push('')
            return { ...elem }
        }))

    }

    const handleAddNoteRow = () => {
        if (!selectedCompany) return
        notes.push({
            title: `Note ${notes.length + 1}`,
            values: quarters.map(elem => '')
        })
        setNotes([...notes])
    }

    const handleSave = async () => {
        if (!selectedCompany) return
        setProcessing(true)
        try {
            const dataResp = await trackerAPI.storeTrackLines({
                company: selectedCompany,
                quarters,
                trackLines,
                formFiled, formUsed, refundTypes, dateReceived, notes,
                totalRefundReceived, form941x
            })
            setNotes(dataResp.data.notes || [])
            AlertUtil.showSuccessAlert(storeNotification, "Track Data is stored")
        } catch (e) {
            const m = e.message ? e.message : e.toString()
            AlertUtil.showErrorAlert(storeNotification, m)
        }
        setProcessing(false)
    }

    const handleLocationChanges = (location) => {
        setIsDeleteCol(!FIXED_COLUMNS.includes(location.columnId))
        setIsDeleteRow(!FIXED_ROWS.find(elem => location.rowId.indexOf(elem) == 0))
        setCurrentColId(location.columnId)
        setCurrentRowId(location.rowId)
    }

    const handleDeleteRow = async () => {
        if (!selectedCompany || !currentRowId) return
        try {
            if (window.confirm("Are you sure to delete row?")) {
                await trackerAPI.deleteTrackLines(currentRowId)
                setTrackLines(trackLines.filter(elem => elem._id != currentRowId))
                setCurrentRowId('')
            }
        } catch (e) {
            const m = e.message ? e.message : e.toString()
            AlertUtil.showErrorAlert(storeNotification, m)
        }
    }

    const handleDeleteCol = () => {
        if (!selectedCompany || !isDeleteRow) return
        if (!window.confirm("Are you sure to delete column?")) {
            return
        }
        setUtilColumns(quarters.filter((elem, index) => index != currentColId).map((elem, index) => {
            return {
                columnId: index,
                width: 100
            }
        }))
        setQuarters(quarters.filter((elem, index) => index != currentColId))
        const trackData = trackLines.map(elem => {
            return { ...elem, tax_values: elem.tax_values.filter((elem, index) => index != currentColId) }
        })
        setTrackLines(trackData)

        setFormFiled(formFiled.filter((elem, index) => index != currentColId))

        setFormUsed(formUsed.filter((elem, index) => index != currentColId))

        setForm941x(form941x.filter((elem, index) => index != currentColId))

        setRefundTypes(refundTypes.filter((elem, index) => index != currentColId))

        setDateReceived(dateReceived.filter((elem, index) => index != currentColId))

        setTotalRefundReceived(totalRefundReceived.filter((elem, index) => index != currentColId))

        setAttach(attach.filter((elem, index) => index != currentColId))
        setNotes(notes.map(elem => {
            return { ...elem, values: elem.values.filter((elem, index) => index != currentColId) }
        }))

    }

    const handleViewPDF = async () => {
        if (!selectedCompany || !isDeleteCol) return
        setLoading(true)
        try {
            await trackerAPI.storeTrackLines({
                company: selectedCompany,
                quarters,
                trackLines,
                formFiled, formUsed, refundTypes, dateReceived, notes,
                totalRefundReceived, form941x
            })

            const pdfResp = await trackerAPI.exportPDF({
                company: selectedCompany,
                form_type: formType,
                quarter: quarters[currentColId]
            })

            const pdfURL = pdfResp.data.name || ''
            if (pdfURL) {
                window.open(`${Constants.API_URL}/storage/pdf?path=${pdfURL}`, "_blank");
            }

        } catch (e) {
            const m = e.message ? e.message : e.toString()
            AlertUtil.showErrorAlert(storeNotification, m)
        }
        setLoading(false)
    }

    const handleDownload = async () => {
        if (!selectedCompany) return

        const companyData = companies.find(e => e._id == selectedCompany)
        if(!companyData){
            AlertUtil.showErrorAlert(storeNotification, "Company does not exist")
        }

        let link = document.createElement("a");
        link.setAttribute('download', companyData.name);
        link.setAttribute('target', '_blank');
        link.href = `${Constants.API_URL}/track/excel?access_token=${accessToken}&company=${selectedCompany}`;
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    const handleUploadFile = async (e, quarter, index) => {
        const formData = new FormData();
        for (let i = 0; i < e.target.files.length; i++) {
            formData.append("file", e.target.files[i]);
        }
        formData.append("quarter", quarter);
        formData.append("company", selectedCompany);


        try {
            const resp = await trackerAPI.uploadAttachment(formData)
            attach[index] = resp.data
            setAttach([...attach])
            AlertUtil.showSuccessAlert(storeNotification, "Uploading attchment is done.")
        } catch (ex) {
            const m = e.message ? e.message : e.toString()
            AlertUtil.showErrorAlert(storeNotification, m)
        }
    }

    const handleAttachView = async (id) => {
        if (!id) return
        try {
            window.open(`${Constants.API_URL}/storage/attach?id=${id}`, "_blank");
        } catch (e) {
            const m = e.message ? e.message : e.toString()
            AlertUtil.showErrorAlert(storeNotification, m)
        }
    }

    const handleRemoveFile = async (item, index) => {
        if (!item) return
        if (window.confirm("Are you sure to delete attachment?")) {
            try {
                await trackerAPI.deleteAttachment(item._id)
                const rowAttach = attach[index].filter(elem => elem._id != item._id)
                attach[index] = rowAttach
                setAttach([...attach])
                AlertUtil.showSuccessAlert(storeNotification, "Attachment is removed.")
            } catch (e) {
                const m = e.message ? e.message : e.toString()
                AlertUtil.showErrorAlert(storeNotification, m)
            }
        }
    }

    return (
        <div className={`${classes.container} tracker-wrapper`}>
            <Typography variant="h4" className="pl-40">Tracker</Typography>
            <Box display="flex" justifyContent="center" className="mb-12" alignItems="center">
                <FormControl variant="filled">
                    <PSelect
                        labelId="company-label"
                        variant="filled"
                        displayEmpty
                        IconComponent={IconDownArrow}
                        value={selectedCompany} onChange={(e) => handleChangeCompany(e.target.value)}
                        className={classes.selector}
                    >
                        {([{ _id: '', name: 'Select a Company' }, ...companies] || []).map((item, index) => (
                            <MenuItem value={item._id} key={index}>{item.name}</MenuItem>
                        ))}
                    </PSelect>
                </FormControl>

                <PButton variant="outlined" color="primary" className="mr-12" onClick={handleAddRow} disabled={!selectedCompany}>Add Row</PButton>
                <PButton variant="outlined" color="primary" className="mr-12" onClick={handleAddQuarterColumn} disabled={!selectedCompany}>Add Quarter Column</PButton>
                <PButton variant="outlined" color="primary" className="mr-12" onClick={handleAddNoteRow} disabled={!selectedCompany}>Add Note Row</PButton>
                <PButton variant="outlined" color="primary" className={`${classes.deleteButton} mr-12`} onClick={handleDeleteRow} disabled={!selectedCompany || !isDeleteRow}>Delete Row</PButton>
                <PButton variant="outlined" color="primary" className={`${classes.deleteButton} mr-36`} onClick={handleDeleteCol} disabled={!selectedCompany || !isDeleteCol}>Delete Col</PButton>

                <div className={classes.wrapperButton}>
                    <PButton variant="contained" color="primary" className="mr-12" onClick={handleSave} disabled={!selectedCompany}>Save</PButton>
                    {processing && (
                        <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                        />
                    )}
                </div>

                <FormControl variant="filled">
                    <PSelect
                        labelId="company-label"
                        variant="filled"
                        displayEmpty
                        IconComponent={IconDownArrow}
                        value={formType} onChange={(e) => setFormType(e.target.value)}
                        className={classes.selector}
                    >
                        {FORM_TYPES.map((item, index) => (
                            <MenuItem value={item} key={index}>{item}</MenuItem>
                        ))}
                    </PSelect>
                </FormControl>

                <div className={classes.wrapperButton}>
                    <PButton variant="contained" color="primary" className="mr-12" onClick={handleViewPDF} disabled={!selectedCompany || !isDeleteCol}>View PDF {quarters[currentColId]}</PButton>
                    {loading && (
                        <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                        />
                    )}
                </div>

                <div className={classes.wrapperButton}>
                    <PButton variant="contained" color="primary" className="" onClick={handleDownload} disabled={!selectedCompany}>
                        Download
                    </PButton>
                </div>

            </Box>
            <div className={`${classes.container}`}>
                <ReactGrid
                    rows={rows}
                    columns={columns}
                    onCellsChanged={handleChanges}
                    onFocusLocationChanged={handleLocationChanges}
                    stickyTopRows={1}
                    stickyLeftColumns={1}
                    stickyRightColumns={1}
                    // props below are availble for PRO version
                    enableFillHandle
                    enableRangeSelection
                    customCellTemplates={{ tooltip: new TooltipCellTemplate() }}
                />
            </div>
            <div className={`${classes.container} p-40`}>
                <Typography variant="h5">Attachments</Typography>
                <table className={classes.attachments}>
                    <tbody>
                        <tr>
                            <td><b>Quarter</b></td>
                            {quarters.map((item, index) => <td key={index} className={`${index === currentColId ? 'currentCol' : ''}`}>{item}</td>)}
                        </tr>
                        <tr>
                            <td><b>Attachment</b></td>
                            {quarters.map((item, index) => (
                                <td key={index} className={`${index === currentColId ? 'currentCol' : ''}`}>
                                    <ul className="p-0">
                                        {(attach[index] || []).map((elem, attachIndex) => (
                                            <li className="d-flex">
                                                <Link onClick={(e) => handleAttachView(elem._id)} disabled={!elem}>{elem ? elem.name : ''}</Link>
                                                <IconButton
                                                    size="small"
                                                    onClick={(e) => handleRemoveFile(elem, index)}
                                                    color="danger"
                                                    component="span">
                                                    <Delete />
                                                </IconButton>
                                            </li>
                                        ))}
                                    </ul>
                                </td>
                            ))}
                        </tr>
                        <tr>
                            <td><b>Action</b></td>
                            {quarters.map((item, index) =>
                                <td key={index} className={`${index === currentColId ? 'currentCol' : ''}`}>
                                    <input
                                        accept="*"
                                        className={classes.uploadInput}
                                        id={`upload-${index}`}
                                        multiple
                                        type="file"
                                        onChange={(e) => handleUploadFile(e, item, index)}
                                    />
                                    <label htmlFor={`upload-${index}`}>
                                        <PButton variant="contained" color="primary" component="span">
                                            Upload
                                        </PButton>
                                    </label>

                                </td>)}
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
